$font-size-base: 0.875rem;
$line-height-base: 1.1;
$body-bg: #4D4D4D;

$theme-colors: (
  "body": #f4f4f4,
  "primary": #1F7873,
  "black": #1b1b1b,
  "greywhite": #dadce6,
  "white": #FFFFFF,
  "darkgrey": #1F7873,
  "lightgrey": #898989,
  "titlegray": #6A6F80,
  "gray": #F1F1F1,
  "dark-slate-gray": #4B4E58,
  "bold-gray": #52575A,
  "bold-black": #0A001F,
  "light-blue": #ECF3FD,
  "green": #529968,
  "red": #D74A49,
  "filbody" : #f5f4f9,
  "table-title": #c2f0f0,
  "table-font": #000000,
  'trolleygrey': #808080,
);